<template>
    <div class="informationen">
        <h1 class="text-center">Informationen</h1>


        <div class="container-fluid margin-top-50 narrow">
            <div class="row">

                <div class="col-xs-12 col-ms-6 col-sm-6">
                    <p class="h3">Beginn</p>
                    <p>Das Gigampfijahr beginnt eine Woche nach Schulbeginn also am 26.08.2024 und endet eine Woche vor Schulschluss. Ansonsten richten wir uns nach dem Ferienplan der Schulen Hochdorf.
                    </p>
                </div>
                <div class="col-xs-12 col-ms-6 col-sm-6">
                    <p class="h3">Eintrittsalter</p>
                    <p>Der Stichtag für das Eintrittsalter ist wie folgt.
                    <br/><br/>
                    2x Wöchentlich: 01.08.2019 - 31.12.2020<br/>
                    1x Wöchentlich: 01.01.2021 - 31.08.2021
                    </p>
                </div>
                <div class="col-xs-12 col-ms-6 col-sm-6">
                    <p class="h3">Zeiten</p>
                    <p>
                    Vormittag:&nbsp;&nbsp;&nbsp;&nbsp;08:45 - 11:15 h<br/>
                    Nachmittag: 13:30 - 16:00 h
                    </p>
                </div>
                <div class="col-xs-12 col-ms-6 col-sm-6">
                    <p class="h3">Kosten</p>
                    <p>CHF 1220.- pro Kind/Jahr <br/>
                    CHF 720.- pro Kind/Jahr (Mini-Gruppe einmal wöchentlich)</p>
                </div>

            </div>
            <div class="row">

                <div class="col-xs-12 col-ms-6 col-sm-8">
                    <p class="h3">Corona</p>
                    <p>In der folgenden Grafik wird Ihnen veranschaulicht, wie wir mit der aktuellen Situation verantwortungsvoll umgehen.</p>
                </div>

            </div>
        </div>

        <img alt="Corona Vorgehen" class="noline" src="../assets/img/informationen_corona.png">
        <figcaption>Vorgehen bei Kindern bis 12 Jahre OHNE engen Kontakt zu symptomatischer Person gemäss SSLV (Schweizer Spielgruppen-Leiterinnen-Verband)</figcaption>

        <div class="narrow">
            <p class="h3">A bis Z</p>
            <p>Die häufigsten Fragen und Antworten finden Sie in den folgende Dokumenten zum Download:</p>
            <p>
                <ul>
                    <li><a href="./pdf/A-Z_klein.pdf" class="files" target="_blank">Informationen von A-Z (Klein).pdf</a></li>
                    <li><a href="./pdf/A-Z_gross.pdf" class="files" target="_blank">Informationen von A-Z (Gross).pdf</a></li>
                    <li><a href="./pdf/Information_Gigampfi_2024.pdf" class="files" target="_blank">Informationen Gigampfi 2024.pdf</a></li>
                </ul>
            </p>
        </div>

        <div class="margin-top-50">
            <AllePersonen title="Ansprechpersonen" sub="true" v-bind:filter="['rosanna-muff','martina-ineichen','stefanie-amrein']" desc=""/>
        </div>

    </div>

</template>

<script>
import AllePersonen from '@/components/AllePersonen.vue'

export default {
    name: 'Informationen',
    components: {
        AllePersonen
    }
}
</script>
